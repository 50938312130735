/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const SET_ACTIVITIES_TO_CACHE = 'SET_ACTIVITIES_TO_CACHE';
export const ADD_ACTIVITIES_TO_CACHE = 'ADD_ACTIVITIES_TO_CACHE';

export const CHANGE_USER_ACTION = 'CHANGE_USER_ACTION';

export const CHANGE_LESSON_STATE_PASS = 'CHANGE_LESSON_STATE_PASS';
export const CHANGE_LESSON_STATE_FAIL = 'CHANGE_LESSON_STATE_FAIL';
export const CHANGE_LESSON_STATE_INIT = 'CHANGE_LESSON_STATE_INIT';
