/*
 * reducers/auth.js
 *
 * Defines the reducers.
 *
 * A reducer takes the current state and an action object as input, and returns the new state:
 * (state, action) => newState
 * 
 * A reducer is like an event listener, which handles events based on the action type.
 *
 * Redux expects all state updates are done immutably.
 * 
 * We use the spread operator ... in JavaScript to ensure state immutability. That is, we use ... to
 * clone the state and then only update a portion from the copy.
 */
import { CHANGE_LESSON_STATE_PASS, CHANGE_LESSON_STATE_FAIL, CHANGE_LESSON_STATE_INIT } from '../constants';
import { CHANGE_USER_ACTION } from '../constants';
import { SET_ACTIVITIES_TO_CACHE, ADD_ACTIVITIES_TO_CACHE } from '../constants';

export default function auth(state = null, action) {
  if (state === null) {
    return {
      lessonState: {},
      user: {},
      activities: [],
    };
  }

  switch (action.type) {
    case CHANGE_LESSON_STATE_PASS:
    case CHANGE_LESSON_STATE_FAIL:
    case CHANGE_LESSON_STATE_INIT:
    {
      return {
        ...state,
        lessonState: action.lessonState,
      };
    }

    case CHANGE_USER_ACTION: {
      return {
        ...state,
        user: action.user,
      };
    }

    case SET_ACTIVITIES_TO_CACHE: {
      return {
        ...state,
        activities: [...action.payload.activities]
      };
    }

    case ADD_ACTIVITIES_TO_CACHE: {
      return {
        ...state,
        activities: [...state.activities, ...action.payload.activities]
      };
    }

    default: {
      return state;
    }
  }
}
