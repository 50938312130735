/*
* routes/index.js
*/

/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/run',
      load: () => import(/* webpackChunkName: 'run' */ './run'),
    },
    {
      path: '/project',
      load: () => import(/* webpackChunkName: 'project' */ './project'),
    },
    {
      path: '/projects',
      load: () => import(/* webpackChunkName: 'projects' */ './projects'),
    },
    {
      path: '/create-project',
      load: () => import(/* webpackChunkName: 'create-project' */ './create-project'),
    },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/signup',
      load: () => import(/* webpackChunkName: 'signup' */ './signup'),
    },
    {
      path: '/about',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    {
      path: '/payment',
      load: () => import(/* webpackChunkName: 'payment' */ './payment'),
    },
    {
      path: '/privacy',
      load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    },
    {
      path: '/admin',
      load: () => import(/* webpackChunkName: 'admin' */ './admin'),
    },
    {
      path: '/classes',
      load: () => import(/* webpackChunkName: 'classes' */ './classes'),
    },
    {
      path: '/learn',
      load: () => import(/* webpackChunkName: 'learn' */ './learn'),
    },
    {
      path: '/nuggets',
      load: () => import(/* webpackChunkName: 'nuggets' */ './nuggets'),
    },
    {
      path: '/create-nugget',
      load: () => import(/* webpackChunkName: 'create-nugget' */ './create-nugget'),
    },
    {
      path: '/snippet',
      load: () => import(/* webpackChunkName: 'snippet' */ './snippet'),
    },
    {
      path: '/snippets',
      load: () => import(/* webpackChunkName: 'snippets' */ './snippets'),
    },
    {
      path: '/create-snippet',
      load: () => import(/* webpackChunkName: 'create-snippet' */ './create-snippet'),
    },
    {
      path: '/note',
      load: () => import(/* webpackChunkName: 'note' */ './note'),
    },
    {
      path: '/notes',
      load: () => import(/* webpackChunkName: 'notes' */ './notes'),
    },
    {
      path: '/create-note',
      load: () => import(/* webpackChunkName: 'create-note' */ './create-note'),
    },
    {
      path: '/quiz',
      load: () => import(/* webpackChunkName: 'quiz' */ './quiz'),
    },
    {
      path: '/quizzes',
      load: () => import(/* webpackChunkName: 'quizzes' */ './quizzes'),
    },
    {
      path: '/create-quiz',
      load: () => import(/* webpackChunkName: 'create-quiz' */ './create-quiz'),
    },
    {
      path: '/lesson',
      load: () => import(/* webpackChunkName: 'lesson' */ './lesson'),
    },
    {
      path: '/lessons',
      load: () => import(/* webpackChunkName: 'lessons' */ './lessons'),
    },
    {
      path: '/create-lesson',
      load: () => import(/* webpackChunkName: 'create-lesson' */ './create-lesson'),
    },
    {
      path: '/course',
      load: () => import(/* webpackChunkName: 'course' */ './course'),
    },
    {
      path: '/courses',
      load: () => import(/* webpackChunkName: 'courses' */ './courses'),
    },
    {
      path: '/create-course',
      load: () => import(/* webpackChunkName: 'create-course' */ './create-course'),
    },
    {
      path: '/curriculum',
      load: () => import(/* webpackChunkName: 'curriculum' */ './curriculum'),
    },
    {
      path: '/python-pad',
      load: () => import(/* webpackChunkName: 'python-pad' */ './python-pad'),
    },
    {
      path: '/profile',
      load: () => import(/* webpackChunkName: 'profile' */ './profile'),
    },
    {
      path: '/logout',
      load: () => import(/* webpackChunkName: 'profile' */ './logout'),
    },
    {
      path: '/classroom',
      load: () => import(/* webpackChunkName: 'classroom' */ './classroom'),
    },
    {
      path: '/classrooms',
      load: () => import(/* webpackChunkName: 'classrooms' */ './classrooms'),
    },
    {
      path: '/create-classroom',
      load: () => import(/* webpackChunkName: 'create-classroom' */ './create-classroom'),
    },
    {
      path: '/cheatsheet',
      load: () => import(/* webpackChunkName: 'cheatsheet' */ './cheatsheet'),
    },
    {
      path: '/gallery',
      load: () => import(/* webpackChunkName: 'gallery' */ './gallery'),
    },
    {
      path: '/demo',
      load: () => import(/* webpackChunkName: 'demo' */ './demo'),
    },
    {
      path: '/sample',
      load: () => import(/* webpackChunkName: 'sample' */ './sample'),
    },
    {
      path: '/funfacts',
      load: () => import(/* webpackChunkName: 'funfacts' */ './funfacts'),
    },
    {
      path: '/faq',
      load: () => import(/* webpackChunkName: 'faq' */ './faq'),
    },
    {
      path: '/radar',
      load: () => import(/* webpackChunkName: 'radar' */ './radar'),
    },
    {
      path: '/update-settings',
      load: () => import(/* webpackChunkName: 'update-settings' */ './update-settings'),
    },

    {
      path: '/python1',
      load: () => import(/* webpackChunkName: 'python1' */ './curriculum/python1'),
    },
    {
      path: '/python2',
      load: () => import(/* webpackChunkName: 'python2' */ './curriculum/python2'),
    },
    {
      path: '/algorithms1',
      load: () => import(/* webpackChunkName: 'algorithms1' */ './curriculum/algorithms1'),
    },
    {
      path: '/algorithms2',
      load: () => import(/* webpackChunkName: 'algorithms2' */ './curriculum/algorithms2'),
    },
    {
      path: '/algorithms3',
      load: () => import(/* webpackChunkName: 'algorithms3' */ './curriculum/algorithms3'),
    },
    {
      path: '/game-programming-with-turtle',
      load: () => import(/* webpackChunkName: 'game-programming-with-turtle' */ './curriculum/game-programming-with-turtle'),
    },
    {
      path: '/introduction-to-pygame',
      load: () => import(/* webpackChunkName: 'introduction-to-pygame' */ './curriculum/introduction-to-pygame'),
    },
    {
      path: '/shooting-game',
      load: () => import(/* webpackChunkName: 'shooting-game' */ './curriculum/shooting-game'),
    },
    {
      path: '/platform-game',
      load: () => import(/* webpackChunkName: 'platform-game' */ './curriculum/platform-game'),
    },
    {
      path: '/acsl',
      load: () => import(/* webpackChunkName: 'acsl' */ './curriculum/acsl'),
    },
    {
      path: '/Level5',
      load: () => import(/* webpackChunkName: 'Level5' */ './curriculum/Level5'),
    },
    {
      path: '/Level6',
      load: () => import(/* webpackChunkName: 'Level6' */ './curriculum/Level6'),
    },
    {
      path: '/Level7',
      load: () => import(/* webpackChunkName: 'Level7' */ './curriculum/Level7'),
    },
    {
      path: '/Level8',
      load: () => import(/* webpackChunkName: 'Level8' */ './curriculum/Level8'),
    },
    {
      path: '/Level9',
      load: () => import(/* webpackChunkName: 'Level9' */ './curriculum/Level9'),
    },

    {
      path: '/courseware/computer/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computer/task'),
    },
    {
      path: '/courseware/computer/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computer/tutorial'),
    },
    {
      path: '/courseware/computer/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computer/challenge'),
    },
    {
      path: '/courseware/computer/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computer/lesson'),
    },
    {
      path: '/courseware/computer/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computer/note'),
    },

    {
      path: '/courseware/python/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/python/task'),
    },
    {
      path: '/courseware/python/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/python/tutorial'),
    },
    {
      path: '/courseware/python/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/python/challenge'),
    },
    {
      path: '/courseware/python/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/python/lesson'),
    },
    {
      path: '/courseware/python/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/python/note'),
    },

    {
      path: '/courseware/turtle/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtle/task'),
    },
    {
      path: '/courseware/turtle/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtle/tutorial'),
    },
    {
      path: '/courseware/turtle/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtle/challenge'),
    },
    {
      path: '/courseware/turtle/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtle/lesson'),
    },
    {
      path: '/courseware/turtle/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtle/note'),
    },

    {
      path: '/courseware/variables/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/variables/task'),
    },
    {
      path: '/courseware/variables/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/variables/tutorial'),
    },
    {
      path: '/courseware/variables/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/variables/challenge'),
    },
    {
      path: '/courseware/variables/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/variables/lesson'),
    },
    {
      path: '/courseware/variables/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/variables/note'),
    },

    {
      path: '/courseware/strings/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/strings/task'),
    },
    {
      path: '/courseware/strings/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/strings/tutorial'),
    },
    {
      path: '/courseware/strings/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/strings/challenge'),
    },
    {
      path: '/courseware/strings/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/strings/lesson'),
    },
    {
      path: '/courseware/strings/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/strings/note'),
    },

    {
      path: '/courseware/numbers/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/numbers/task'),
    },
    {
      path: '/courseware/numbers/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/numbers/tutorial'),
    },
    {
      path: '/courseware/numbers/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/numbers/challenge'),
    },
    {
      path: '/courseware/numbers/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/numbers/lesson'),
    },
    {
      path: '/courseware/numbers/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/numbers/note'),
    },

    {
      path: '/courseware/conditional/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/conditional/task'),
    },
    {
      path: '/courseware/conditional/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/conditional/tutorial'),
    },
    {
      path: '/courseware/conditional/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/conditional/challenge'),
    },
    {
      path: '/courseware/conditional/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/conditional/lesson'),
    },
    {
      path: '/courseware/conditional/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/conditional/note'),
    },

    {
      path: '/courseware/loops/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/loops/task'),
    },
    {
      path: '/courseware/loops/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/loops/tutorial'),
    },
    {
      path: '/courseware/loops/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/loops/challenge'),
    },
    {
      path: '/courseware/loops/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/loops/lesson'),
    },
    {
      path: '/courseware/loops/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/loops/note'),
    },

    {
      path: '/courseware/functions/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/functions/task'),
    },
    {
      path: '/courseware/functions/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/functions/tutorial'),
    },
    {
      path: '/courseware/functions/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/functions/challenge'),
    },
    {
      path: '/courseware/functions/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/functions/lesson'),
    },
    {
      path: '/courseware/functions/note',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/functions/note'),
    },

    {
      path: '/courseware/demo1/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/demo1/task'),
    },
    {
      path: '/courseware/demo1/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/demo1/tutorial'),
    },
    {
      path: '/courseware/demo1/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/demo1/challenge'),
    },
    {
      path: '/courseware/demo1/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/demo1/lesson'),
    },

    {
      path: '/courseware/objects/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/objects/task'),
    },
    {
      path: '/courseware/objects/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/objects/tutorial'),
    },
    {
      path: '/courseware/objects/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/objects/challenge'),
    },
    {
      path: '/courseware/objects/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/objects/lesson'),
    },

    {
      path: '/courseware/modules/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/modules/task'),
    },
    {
      path: '/courseware/modules/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/modules/tutorial'),
    },
    {
      path: '/courseware/modules/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/modules/challenge'),
    },
    {
      path: '/courseware/modules/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/modules/lesson'),
    },

    {
      path: '/courseware/scope/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/scope/task'),
    },
    {
      path: '/courseware/scope/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/scope/tutorial'),
    },
    {
      path: '/courseware/scope/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/scope/challenge'),
    },
    {
      path: '/courseware/scope/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/scope/lesson'),
    },

    {
      path: '/courseware/lists/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/lists/task'),
    },
    {
      path: '/courseware/lists/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/lists/tutorial'),
    },
    {
      path: '/courseware/lists/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/lists/challenge'),
    },
    {
      path: '/courseware/lists/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/lists/lesson'),
    },

    {
      path: '/courseware/dictionaries/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/dictionaries/task'),
    },
    {
      path: '/courseware/dictionaries/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/dictionaries/tutorial'),
    },
    {
      path: '/courseware/dictionaries/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/dictionaries/challenge'),
    },
    {
      path: '/courseware/dictionaries/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/dictionaries/lesson'),
    },

    {
      path: '/courseware/turtlegame/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtlegame/task'),
    },
    {
      path: '/courseware/turtlegame/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtlegame/tutorial'),
    },
    {
      path: '/courseware/turtlegame/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtlegame/challenge'),
    },
    {
      path: '/courseware/turtlegame/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/turtlegame/lesson'),
    },

    {
      path: '/courseware/animation/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/animation/task'),
    },
    {
      path: '/courseware/animation/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/animation/tutorial'),
    },
    {
      path: '/courseware/animation/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/animation/challenge'),
    },
    {
      path: '/courseware/animation/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/animation/lesson'),
    },

    {
      path: '/courseware/event/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/event/task'),
    },
    {
      path: '/courseware/event/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/event/tutorial'),
    },
    {
      path: '/courseware/event/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/event/challenge'),
    },
    {
      path: '/courseware/event/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/event/lesson'),
    },

    {
      path: '/courseware/pygame/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/pygame/task'),
    },
    {
      path: '/courseware/pygame/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/pygame/tutorial'),
    },
    {
      path: '/courseware/pygame/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/pygame/challenge'),
    },
    {
      path: '/courseware/pygame/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/pygame/lesson'),
    },

    {
      path: '/courseware/algorithms/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/algorithms/task'),
    },
    {
      path: '/courseware/algorithms/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/algorithms/tutorial'),
    },
    {
      path: '/courseware/algorithms/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/algorithms/challenge'),
    },
    {
      path: '/courseware/algorithms/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/algorithms/lesson'),
    },

    {
      path: '/courseware/recursion/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/recursion/task'),
    },
    {
      path: '/courseware/recursion/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/recursion/tutorial'),
    },
    {
      path: '/courseware/recursion/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/recursion/challenge'),
    },
    {
      path: '/courseware/recursion/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/recursion/lesson'),
    },

    {
      path: '/courseware/divideconquer/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/divideconquer/task'),
    },
    {
      path: '/courseware/divideconquer/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/divideconquer/tutorial'),
    },
    {
      path: '/courseware/divideconquer/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/divideconquer/challenge'),
    },
    {
      path: '/courseware/divideconquer/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/divideconquer/lesson'),
    },

    {
      path: '/courseware/searching/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/searching/task'),
    },
    {
      path: '/courseware/searching/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/searching/tutorial'),
    },
    {
      path: '/courseware/searching/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/searching/challenge'),
    },
    {
      path: '/courseware/searching/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/searching/lesson'),
    },

    {
      path: '/courseware/simplesorts/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/simplesorts/task'),
    },
    {
      path: '/courseware/simplesorts/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/simplesorts/tutorial'),
    },
    {
      path: '/courseware/simplesorts/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/simplesorts/challenge'),
    },
    {
      path: '/courseware/simplesorts/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/simplesorts/lesson'),
    },

    {
      path: '/courseware/gamedesign/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/gamedesign/task'),
    },
    {
      path: '/courseware/gamedesign/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/gamedesign/tutorial'),
    },
    {
      path: '/courseware/gamedesign/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/gamedesign/challenge'),
    },
    {
      path: '/courseware/gamedesign/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/gamedesign/lesson'),
    },

    {
      path: '/courseware/file/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/file/task'),
    },
    {
      path: '/courseware/file/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/file/tutorial'),
    },
    {
      path: '/courseware/file/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/file/challenge'),
    },
    {
      path: '/courseware/file/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/file/lesson'),
    },

    {
      path: '/courseware/action/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/action/task'),
    },
    {
      path: '/courseware/action/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/action/tutorial'),
    },
    {
      path: '/courseware/action/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/action/challenge'),
    },
    {
      path: '/courseware/action/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/action/lesson'),
    },

    {
      path: '/courseware/mergesort/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/mergesort/task'),
    },
    {
      path: '/courseware/mergesort/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/mergesort/tutorial'),
    },
    {
      path: '/courseware/mergesort/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/mergesort/challenge'),
    },
    {
      path: '/courseware/mergesort/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/mergesort/lesson'),
    },

    {
      path: '/courseware/quicksort/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/quicksort/task'),
    },
    {
      path: '/courseware/quicksort/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/quicksort/tutorial'),
    },
    {
      path: '/courseware/quicksort/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/quicksort/challenge'),
    },
    {
      path: '/courseware/quicksort/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/quicksort/lesson'),
    },

    {
      path: '/courseware/hash/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/hash/task'),
    },
    {
      path: '/courseware/hash/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/hash/tutorial'),
    },
    {
      path: '/courseware/hash/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/hash/challenge'),
    },
    {
      path: '/courseware/hash/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/hash/lesson'),
    },

    {
      path: '/courseware/computational/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computational/task'),
    },
    {
      path: '/courseware/computational/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computational/tutorial'),
    },
    {
      path: '/courseware/computational/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computational/challenge'),
    },
    {
      path: '/courseware/computational/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/computational/lesson'),
    },

    {
      path: '/courseware/platformer/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/platformer/task'),
    },
    {
      path: '/courseware/platformer/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/platformer/tutorial'),
    },
    {
      path: '/courseware/platformer/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/platformer/challenge'),
    },
    {
      path: '/courseware/platformer/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/platformer/lesson'),
    },

    {
      path: '/courseware/spritesheet/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/spritesheet/task'),
    },
    {
      path: '/courseware/spritesheet/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/spritesheet/tutorial'),
    },
    {
      path: '/courseware/spritesheet/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/spritesheet/challenge'),
    },
    {
      path: '/courseware/spritesheet/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/spritesheet/lesson'),
    },

    {
      path: '/courseware/linkedlist/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/linkedlist/task'),
    },
    {
      path: '/courseware/linkedlist/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/linkedlist/tutorial'),
    },
    {
      path: '/courseware/linkedlist/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/linkedlist/challenge'),
    },
    {
      path: '/courseware/linkedlist/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/linkedlist/lesson'),
    },

    {
      path: '/courseware/stack/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/stack/task'),
    },
    {
      path: '/courseware/stack/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/stack/tutorial'),
    },
    {
      path: '/courseware/stack/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/stack/challenge'),
    },
    {
      path: '/courseware/stack/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/stack/lesson'),
    },

    {
      path: '/courseware/queue/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/queue/task'),
    },
    {
      path: '/courseware/queue/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/queue/tutorial'),
    },
    {
      path: '/courseware/queue/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/queue/challenge'),
    },
    {
      path: '/courseware/queue/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/queue/lesson'),
    },

    {
      path: '/courseware/greedy/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/greedy/task'),
    },
    {
      path: '/courseware/greedy/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/greedy/tutorial'),
    },
    {
      path: '/courseware/greedy/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/greedy/challenge'),
    },
    {
      path: '/courseware/greedy/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/greedy/lesson'),
    },

    {
      path: '/courseware/array/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/array/task'),
    },
    {
      path: '/courseware/array/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/array/tutorial'),
    },
    {
      path: '/courseware/array/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/array/challenge'),
    },
    {
      path: '/courseware/array/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/array/lesson'),
    },

    {
      path: '/courseware/binarytree/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/binarytree/task'),
    },
    {
      path: '/courseware/binarytree/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/binarytree/tutorial'),
    },
    {
      path: '/courseware/binarytree/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/binarytree/challenge'),
    },
    {
      path: '/courseware/binarytree/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/binarytree/lesson'),
    },

    {
      path: '/courseware/expressiontree/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/expressiontree/task'),
    },
    {
      path: '/courseware/expressiontree/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/expressiontree/tutorial'),
    },
    {
      path: '/courseware/expressiontree/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/expressiontree/challenge'),
    },
    {
      path: '/courseware/expressiontree/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/expressiontree/lesson'),
    },

    {
      path: '/courseware/bst/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/bst/task'),
    },
    {
      path: '/courseware/bst/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/bst/tutorial'),
    },
    {
      path: '/courseware/bst/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/bst/challenge'),
    },
    {
      path: '/courseware/bst/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/bst/lesson'),
    },

    {
      path: '/courseware/score/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/score/task'),
    },
    {
      path: '/courseware/score/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/score/tutorial'),
    },
    {
      path: '/courseware/score/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/score/challenge'),
    },
    {
      path: '/courseware/score/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/score/lesson'),
    },

    {
      path: '/courseware/acslcoding/task',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/acslcoding/task'),
    },
    {
      path: '/courseware/acslcoding/tutorial',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/acslcoding/tutorial'),
    },
    {
      path: '/courseware/acslcoding/challenge',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/acslcoding/challenge'),
    },
    {
      path: '/courseware/acslcoding/lesson',
      load: () => import(/* webpackChunkName: 'content' */ './courseware/acslcoding/lesson'),
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
